export const fetchCountries = async () => {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all")
    const data = await response.json()
    const options = data.map(country => {
      return {
        label: country.name.common,
        value: country.alpha2Code,
        icon: country.flags.svg,
        code: country.cca2,
      }
    })
    options.sort((a, b) => a.label.localeCompare(b.label))
    return options
  } catch (error) {
    console.log(error)
  }
}

export const fetchCountryCodes = async () => {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all")
    const data = await response.json()
    const options = data.map(country => {
      return {
        name: country.name.common,
        label: `${country.cca3} (${
          country.idd?.suffixes?.length > 1
            ? country.idd.root
            : country.idd.root + country.idd.suffixes
        })`,
        callingCode:
          country.idd?.suffixes?.length > 1
            ? country.idd.root
            : country.idd.root + country.idd.suffixes,
        icon: country.flags.svg,
      }
    })
    options.sort((a, b) => a.name.localeCompare(b.name))
    return options
  } catch (error) {
    console.log(error)
  }
}

export const fetchCountryCurrencies = async () => {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all")

    const data = await response.json()

    const options = data.map(country => {
      return {
        name: country?.currencies ? Object.keys(country?.currencies)[0] : "",
        label: country?.currencies ? Object.keys(country?.currencies)[0] : "",
        callingCode:
          country.idd?.suffixes?.length > 1
            ? country.idd.root
            : country.idd.root + country.idd.suffixes,
        icon: country.flags.svg,
      }
    })

    return options
  } catch (error) {
    console.log(error)
  }
}
